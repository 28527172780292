
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from './Pages/Inicio/Index';
import Layout from './Components/Layout';
import Preguntas from './Pages/Preguntas';
import Temas from './Pages/Temas';
import Tienda from './Pages/Tienda';
import AppUsa from './Pages/App';
import Nosotros from './Pages/Nosotros';
import NuestrosAsesores from './Pages/NuestrosAsesores';
import LoginPage from './Pages/Login';
import RegistroPage from './Pages/Registro';
import RequireAuth from './Components/Authentication/RequireAuth';
import HomeLog from './Pages/InicioLog';
import BookingLandingPage from './Pages/Booking';
import BookingSchedule from './Pages/Booking/BokingSchedule';
import SucessBooking from './Pages/Booking/sucessBooking';
import CancelBoking from './Pages/Booking/CancelBoking';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* public routes */}
        <Route>
          <Route path="/" element={<Layout/>}>
            <Route path="/" element={<Home/>}/>
          </Route>
          <Route path="home" element={<Home/>}/>
          <Route path="temas" element={<Temas/>}/>
          <Route path="preguntas" element={<Preguntas/>}/>
          <Route path="tienda" element={<Tienda/>}/>
          <Route path='app' element={<AppUsa/>}/>
          <Route path='nosotros' element={<Nosotros/>}/>
          <Route path='agenda' element={<BookingLandingPage/>}/>
          <Route path='booking' element={<BookingSchedule/>}/>
          <Route path='login' element={<LoginPage/>} />
          <Route path='registro' element={<RegistroPage/>} />
          <Route path='Success' element={<SucessBooking/>}/>
          <Route path='Cancel' element={<CancelBoking/>}/>
        </Route>


        {/* Private routes */}

        <Route element={<RequireAuth/>}>
            <Route path='homelog' element={<HomeLog/>}/>
        </Route>

      </Routes>
    
    </div>
  );
}

export default App;
