import React from 'react'
import { Accordion } from 'react-bootstrap'
import './style.css'


export const Qnas= () => {
 

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" >
        <Accordion.Header className='AccHeader'> ¿ REQUIERO VISA ?</Accordion.Header>
        <Accordion.Body className='AccBody'>
        - Visas son siempre requeridas para trabajar en los estados unidos,
        Existen diferentes tipos de Visas  te recomiendo visitar  "travel.state.gov"
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='AccHeader'> ¿ QUÉ TIPO DE EMPLEO ?</Accordion.Header>
        <Accordion.Body className='AccBody'>
        - Todos los empleos requieren Visas para trabajar en los estados unidos,
        Existen diferentes tipos de Visas  te recomiendo visitar  "travel.state.gov"
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='AccHeader'> ¿ PUEDO LLEVAR MIS MUEBLES ?</Accordion.Header>
        <Accordion.Body className='AccBody'>
        -  Si , te recomendamos que si tienes muebles y te ofrecen paquete 
        de relocalización te los traigas, en caso contrario mejor déjalos en 
        tu país
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header className='AccHeader'> ¿ PUEDO LLEVAR MI AUTOMOVIL ? </Accordion.Header>
        <Accordion.Body className='AccBody'>
          - Aunque puedes internar un vehiculo extranjero a los Estados Uidos temporalmente
          siempre y cuando este a tu nombre, sera necesario que lo regreses al pais de procedencia, 
          o que lo importes permanentemente a los Estados Unidos lo cual lleva un costo. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header className='AccHeader'> ¿ CÓMO PUEDO ENCONTRAR TRABAJO ?</Accordion.Header>
        <Accordion.Body className='AccBody'>
         - Puedes buscar una oportunidad en Indeed, LinkedIn, Monster, Career Builder, 
        Flexjobs (para búsqueda de trabajo), JobBank, Jooble ..
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
