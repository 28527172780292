import React from 'react'
import Registro from '../../Components/RegForm'
import Footer from '../../Components/Footer'

const RegistroPage = () => {
  return (
    <div>
        <Registro/>
        <Footer/>
        
    </div>
  )
}

export default RegistroPage