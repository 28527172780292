// A Rodolfo no le gusta usar react-redux para que guarde la sesión de la sesion
// en cambio le gusta hacerlo de esta manera mil veces mas dificil
// gracias Rodolfo. -Gena

//use this file for all context to react app
import React, {createContext, useState,useEffect,useReducer} from "react";
import { UserReducer,ApptReducer } from "../Hooks/reducers";
const AuthContext = createContext({})

export const AuthProvider= ({children})=>{

    //all universal variables declaration 
    const [auth,setAuth]=useState({});
    const [booking,setBooking]=useState(false)
    const [showGuide,setShowGuide]=useState(false)
    const [meetingInterval,setMeetingInterval]=useState("15")
    const [privacy,setPrivacy]=useState(false);
    const [terms,setTerms]=useState(false);
    const [question,setQuestion]=useState(false);
    const [userState,userDispatch]=useReducer(UserReducer,{
        username:"",
        FirstName: "",
        LastName: "",
        email:"",
        password:""
    })
    const [apptState,apptDispatch]=useReducer(ApptReducer,{
        name:"",
        email:"",
        whatss:"",
        duration:"",
        appointment:"",
    })

    // include all calls and processes that must execute previous rendering     
    useEffect(()=>{
        },[])

    return(
        <div>
            <AuthContext.Provider value={{auth,setAuth,terms,setTerms,showGuide,setShowGuide,privacy,setPrivacy, question,setQuestion, userState,userDispatch,
            meetingInterval,setMeetingInterval,apptState,apptDispatch,booking,setBooking}}>
                {children}
            </AuthContext.Provider>
        </div>
    )
}

export default AuthContext;