import React from 'react'
import { Card } from 'react-bootstrap'
import LogoAtrevete from "../../assets/logo_provisional.png"
import '../Login/styles.css'


const CalCard = (props) => {
  return (
    <div >
    <div className="d-flex justify-content-center">
         <Card className="LoginCard mt-5" style={{ width: '21rem',   }}>
           <h1>Aparta tú cita!</h1>
           <div className="Cardlogo">
             <img src={LogoAtrevete} alt="logomain"/>
           </div>           
           <Card.Body>
             {props.children}
             <Card.Link href="/registro" className="liga">Registrate con nosotros!</Card.Link>
           </Card.Body>
         </Card>
   </div>
 </div> 
  )
}

export default CalCard