import React from 'react'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'
import OneOfUs from '../../Components/ThisIsUs'
import { Container } from 'react-bootstrap'

const Nosotros = () => {
  return (
    <div>

        <Navegador />
        <Container className='d-flex justify-content-center align-items-center Content'>
        <OneOfUs/>
        </Container>
        
        <Footer/>
    </div>
  )
}

export default Nosotros