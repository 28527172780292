import React from 'react'
import Navegador from '../../Components/NavBar'
import '../Inicio/Style.css';
import {Container} from 'react-bootstrap';
import Footer from '../../Components/Footer';
import SuccessPayment from '../../Components/Modals/SuccessPayment';


const SucessBooking = () => {
  return (
    <div>
        <Navegador/>
        <Container fluid className='Content'>
            <SuccessPayment />
        </Container>
        <Footer/>
    
    </div>
  )
}

export default SucessBooking