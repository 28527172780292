import React, {useState} from 'react'
import { Row, Col,Container,Button, Image } from 'react-bootstrap'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'
import useAuth from '../../Components/Hooks/useAuth'
import Pic1 from '../../assets/Tema1.png'
import Pic2 from '../../assets/Tema2.png'
import Pic3 from '../../assets/Tema3.png'
import './style.css'
import Guide from '../../Components/Guide'


const Temas = () => {
  const {showGuide, setShowGuide}=useAuth();
  const [caseName,setCaseName]=useState('');

  const handleGuide=(e)=>{
    setCaseName(e.target.getAttribute('name'))
    setShowGuide(!showGuide)
  }


  return (
    <div>

    <Navegador />
  
    <Container fluid className='mt-5'>
      <Row className='mt-5'>
        <Col md={3}><h1 className='titles'>Antes de Llegar</h1></Col>
        <Col md={6}><p>¿Buscas una oportunidad de trabajo  o ya tienes una oferta laboral?</p>
          <Button style={{backgroundColor:'#FAF2BF', color:'#072C26'}} onClick={(e)=>{ e.preventDefault();handleGuide(e)}}><h3 className='buttonlegend' name="BeforeArrival">! Click ! <br></br> Para Guía "Antes de Llegar"</h3></Button>
          </Col>
        <Col md={3}>
        <Image className='mt-2' src={Pic1} />
        </Col>
      </Row>
      <Row className='mt-5'>
      <Col md={3}><h1 className='titles'>Cuando Llegas</h1></Col>
        <Col md={6}><p>Ya tienes una visa de trabajo, buscas orientación para tu llegada a Estados</p>
        <Button style={{backgroundColor:'#FAF2BF', color:'#072C26'}} onClick={(e)=>{e.preventDefault(); handleGuide(e)}}><h3 className='buttonlegend' name="OnArrival">! Click ! <br></br> Para Guía "Cuando Llegas"</h3></Button>
        </Col>
        
        <Col md={3}>
        <Image className='mt-2' src={Pic2} />
        </Col>
      </Row>
      <Row className='mt-5'>
      <Col md={3}><h1 className='titles'>Después de llegar</h1></Col>
        <Col md={6}><p>¿Tienes más de 3 meses en Estados Unidos y tienes dudas en trámites y como adaptarte?</p>
        <Button style={{backgroundColor:'#FAF2BF', color:'#072C26'}} onClick={(e)=>{e.preventDefault(); handleGuide(e)}} ><h3 className='buttonlegend' name="AfterArrival">! Click ! <br></br> Para Guía "Después de Llegar"</h3></Button>
        </Col>
        
        <Col md={3}>
          <Image className='mt-3' src={Pic3} />
        </Col>
      </Row>
    </Container>

    {showGuide && (<Guide key={Date.now()} Topic={caseName} ></Guide>)}

    <Footer/>

    </div>
  )
}

export default Temas