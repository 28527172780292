import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../NavBar/style.css";


const Navegador = () => {
  return (
    <Navbar expand="lg" className='nav'>
 
      <Navbar.Toggle aria-controls="basic-navbar-nav" label='Menu' style={{color:'#FAF2BF',fontSize:'14pt'}} ><i className="fa-duotone fa-bars fa-xl"></i> <span className='ms-1' style={{fontSize:'14pt'}}> Menú</span></Navbar.Toggle>
           
        <Navbar.Collapse id="basic-navbar-nav" >
          <Container fluid className='mainNav'>
          <Nav className="me-auto">
            <Nav.Link href="/"><p className='links'>Inicio</p></Nav.Link>
            <Nav.Link href="/temas"><p className='links'>Temas</p></Nav.Link>
            <Nav.Link href="/preguntas"><p className='links'>Preguntas frecuentes</p></Nav.Link>
            <Nav.Link href="/tienda"><p className='links'>Tienda</p></Nav.Link>
            {/* <Nav.Link href="/app"><p className='links'>App</p></Nav.Link> */}
            <Nav.Link href="/agenda" className='ms-5'><p className='links' style={{color:'#FAF2BF'}}>Pide Ayuda!</p></Nav.Link>
            {/* <Nav.Link href="/asesores"><p className='links'>Nuestros Asesores</p></Nav.Link> */}
            
          </Nav>
          </Container>
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/login" role='button' className='links'>Iniciar sesión</a>
            </Navbar.Text>
            
        </Navbar.Collapse>
  
  </Navbar>

  )
}

export default Navegador