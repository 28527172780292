import React from 'react'
import './Style.css'
import Carousel from 'react-bootstrap/Carousel';
import TeamFlag from '../../assets/pic1.jpg'
import BWoman from '../../assets/pic3.jpg'
import Calculator from '../../assets/pic2.jpg'
import {Col,Row,Card, Container} from 'react-bootstrap';
import ReviewCard from '../../Components/ReviewCard';
import Footer from '../../Components/Footer';
import NavegadorLog from '../../Components/NavBarLog';


const HomeLog= () => {
  return (
    <div>
      <NavegadorLog/>
   
        <h1>Bienvenido!</h1>
        <div className='box'>
      <Row>
      <Col md={3}>
        <Container style={{colorBackground:'orange'}} className='position-relative  '>
          <p style={{fontSize:'22pt', color:'#FFFFFF', marginTop:'4rem', fontWeight:'bolder'}} className='p-1'> 
          Somos tus guías en el proceso de buscar, encontrar y aceptar una propuesta laboral en Estados Unidos. 
          </p>
        </Container>
      </Col>
      <Col md={6}>
                
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block "
                      src={TeamFlag}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>Unetenos!</h3>
                      <p>Pregunta a nuestro equipo</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block "
                      src={Calculator}
                      alt="Second slide"
                    />
                  <Carousel.Caption>
                    <h3>Necesitas Una Guía?</h3>
                    <p>Te podemos ayudar</p>
                  </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                   className="d-block"
                  src={BWoman}
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h3>Profesionista en Estados Unidos?</h3>
                  <p>
                    Nosotros te ayudamos en el proceso
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
           
            </Col>
            <Col md={3}>
              <Row className='Reviews'>
                <ReviewCard/>

                

              </Row>


            </Col>








          </Row>
          <Footer/>
        
    </div>
 
    </div>
  )
}

export default HomeLog