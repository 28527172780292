//this is to protect all routes requiring authentication 
import React from "react";
import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from "../Hooks/useAuth";


const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();


    return(
        auth?.isAuthenticated
        ?
            <Outlet />
            : <Navigate to="/Login" state={{from:location}} replace />
    )
}

export default RequireAuth