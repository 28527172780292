import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'
import GooglePlay from '../../assets/App/GooglePlay.png'
import IOS from '../../assets/App/AppleStore.png'

const AppUsa = () => {
  return (
    <div>
      <div className='Content'>        <Navegador />
        <h1>Descarga Próximanmente</h1>
        <Row >
          <Col md={6}>
            <Image src={IOS} alt='logoIosApp'/>
          </Col>
          <Col md={6}>
            <Image src={GooglePlay} alt='logosGooglepay'/>
          </Col>
        </Row>
        </div>
        <Footer/>
    </div>
  )
}

export default AppUsa