import React from 'react'
import LoginFrm from '../../Components/Login';
import Navegador from '../../Components/NavBar';
import { Container } from 'react-bootstrap';
import Footer from '../../Components/Footer';
import Tarjeta from '../../Components/Cards/LogCard';

const LoginPage = () => {
  return (
    <div>
      <Navegador/>
      <Container fluid className='Content'>
        <Tarjeta>
        <LoginFrm/>
        </Tarjeta>
      </Container>
        <Footer/>
    </div>
  )
}

export default LoginPage