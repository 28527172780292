import React from 'react'
import { Container,Row, Col, Button } from 'react-bootstrap'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'
import { Qnas } from '../../Components/QNAs'
import useAuth from '../../Components/Hooks/useAuth'
import SingleQ from '../../Components/Modals/SingleQuestion'

const Preguntas = () => {
  const {question,setQuestion}=useAuth();

  const HandleAction=(e)=>{
    e.preventDefault();
    setQuestion(!question);


  }
  return (
    <div>
        <Navegador />
          <Container className='mt-5 Content'>
          <Qnas/>
          <h2 className='mt-3' style={{fontSize:'16pt'}}>Tienes una pregunta específica?<br></br>
              Te contestaremos a la brevedad</h2>
              <Button  style={{height:"3rem", backgroundColor:'#FAF2BF', color:'#072C26'}} onClick={HandleAction}>Dejanos tu pregunta</Button>
              {question && (<SingleQ/>)}
          </Container>
        <Footer/>
    </div>
  )
}

export default Preguntas