import React from "react"
import { Card} from "react-bootstrap" 
import LogoAtrevete from "../../assets/logo_provisional.png"
import '../Login/styles.css'



const Tarjeta=(props)=>{
    return(
      <div >
         <div className="d-flex justify-content-center">
              <Card className="LoginCard mt-5" style={{ width: '18rem',   }}>
                <div className="Cardlogo">
                  <img src={LogoAtrevete} alt="logomain"/>
                </div>           
                <Card.Body>
                  {props.children}
                  <Card.Link href="/rstpswd" className="liga">¿Olvidaste tu contraseña?</Card.Link>
                </Card.Body>
              </Card>
        </div>
      </div> 
    )
}

export default Tarjeta