import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./styleModal.css"
import useAuth from '../Hooks/useAuth';
import APICall from '../APICalls';


function MyModal(props) {
    const {apptState}=useAuth()

    const HandleConfirm=async()=>{
        console.log(apptState);
        localStorage.setItem('STOAppointment',JSON.stringify(apptState))
      
     
        const Payload={
          product:apptState.duration,
          qty:"1"
        }
        await APICall.Checkout(Payload)
        .then(ans=>window.location.assign(ans.data.url)) // redirecting to external link
        .catch(err=>console.log(err));
    }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Hola ! 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Revisa que los datos sean correctos:</h4>
        <p>
          Se solicitará una cita a nombre de : {apptState.name}
          <br/>
          Para: {apptState.appointment.toLocaleDateString()}
          <br/>
          Se solicitará una cita a nombre de : {apptState.name}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
        <span><i className="fa-duotone fa-trash-can-xmark me-2"/>Cancelar</span>
            </Button>
        <Button id='confirm' variant='success' onClick={()=>{HandleConfirm()}}> 
        <span><i className="fa-duotone fa-badge-check me-2"/>Confirmar</span>
         </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ConfirmationRequest=()=> {
  const {booking,setBooking}=useAuth()

  return (
    <>
  

      <MyModal
        show={booking}
        onHide={() => setBooking(false)}
      />
    </>
  );
 
}

export default ConfirmationRequest