import React from "react";
import { Card, Row, Container,Button } from "react-bootstrap";
import GenCard from "../../Cards/GenericCard";
import WelcomeFrm from "./welcomeForm";






const WelcomeBooking=()=>{
    return (
 
          <Container fluid className='Content'>
            <GenCard>
            <WelcomeFrm/>
            </GenCard>
          </Container>
          
      )

}

export default WelcomeBooking