import React from 'react'
import Navegador from '../../Components/NavBar'
import '../Inicio/Style.css';
import {Container} from 'react-bootstrap';
import Footer from '../../Components/Footer';
import CancelPayment from '../../Components/Modals/CancelPayment';


function CancelBoking() {
  return (
    <div>
        <Navegador/>
        <Container fluid className='Content'>
            <CancelPayment/>
        </Container>
        <Footer/>
    
    </div>
  )
}

export default CancelBoking