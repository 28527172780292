import React from 'react'
import WelcomeBooking from '../../Components/Booking/WelcomeBooking'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'

const BookingLandingPage = () => {
  return (
    <div className='Content'>
    <Navegador />
    <WelcomeBooking />
    <Footer/>
    </div>
  )
}

export default BookingLandingPage