// sevice scrip to authentication 
import axios from "axios"


export const AuthService={

       
        login: async (Payload)=>{
            const answer = await axios.post('/user/login',Payload)
            console.log(answer);
            localStorage.setItem('token', answer.data.user.token );
            return (answer.data)
        },

        register:(payload)=>{
            return axios.post("/user/new",payload)
        },

        logout:async () =>{
            localStorage.clear()
            return axios.get("/user/logout")
            .then(res => res.json())
            .then(data => data);
            
        }





}