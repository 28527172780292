import React from 'react'
import {Card,Row,Colum,Container,Image} from 'react-bootstrap'
import Stars from '../../assets/Stars.png'
const ReviewCard = () => {
    
  return (
    <Container fluid style={{marginTop:'5rem'}}>
      <Card style={{width:'auto', height:'100%',
        backgroundColor:'#072C2620',
        marginRight:'1rem', border:'none'
    
    }}>
      <Card.Header style={{textAlign:'start', border:'none'}}>
        <Card.Img style={{width:'8rem', margin:'0'}} variant='top' src={Stars}/>
      </Card.Header>
      <Card.Body>
      
        <blockquote className="blockquote mb-0">
          <p style={{textAlign:'start', fontSize:'18pt', color:'#FFFFFF', fontWeight:'bolder'}}>
            {' '}
            "Excelente  guia de ayuda, si tu plan es trabajar, vivir y desarrollar tu carrera en USA".{' '}
          </p>
          <footer className="blockquote-footer" style={{textAlign:'start',color:'gold'}}>
            R. Diaz  <cite title="Source Title"><br></br>PUREM</cite>
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
    </Container>
  )
}

export default ReviewCard
