import {React,useState} from 'react'
import {Button,Container} from 'react-bootstrap';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setHours,setMinutes } from 'date-fns';
import useAuth from '../../Hooks/useAuth';



// registerLocale('es', es)

const Calendar = () => {
    const {meetingInterval,apptDispatch,setBooking}= useAuth(); 
    const [startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 30), 16),)

    const handleOnChange=(date)=>{
      
        apptDispatch({
            type:"ADD_APPOINTMENT",
            payload:{
                input:"appointment",
                value:date 
            }
        })
    }

    const handleRequest=(e)=>{
        e.preventDefault();
        setBooking(true)
    }

  return (
  <Container>
<DatePicker
 className='mb-1' 
timeIntervals={parseInt(meetingInterval)}
showIcon
selected={startDate}
onChange={(date) => {setStartDate(date); handleOnChange(date)}}
showTimeSelect
minTime={setHours(setMinutes(new Date(), 30), 16)}
maxTime={setHours(setMinutes(new Date(), 30), 22)}
dateFormat="MMMM d, yyyy h:mm aa"
 > 

 </DatePicker>
 <Button variant='warning' id='Request' className='mb-4' onClick={(e)=>handleRequest(e)}>Solicitar</Button>


  </Container> 

 


  )
}

export default Calendar