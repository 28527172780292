import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../NavBar/style.css";


const NavegadorLog = () => {
  return (
    <Navbar expand="lg" className='nav'>
    
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Container fluid className='mainNav'>
          <Nav className="me-auto">
            <Nav.Link href="/homelog"><p className='links'>Inicio</p></Nav.Link>
            <Nav.Link href="/temaslog"><p className='links'>Temas</p></Nav.Link>
            <Nav.Link href="/preguntaslog"><p className='links'>Preguntas frecuentes</p></Nav.Link>
            <Nav.Link href="/tiendalog"><p className='links'>Tienda</p></Nav.Link>
            <Nav.Link href="/applog"><p className='links'>App</p></Nav.Link>
            <Nav.Link href="/nosotroslog"><p className='links'>Nosotros</p></Nav.Link>
            <Nav.Link href="/asesoreslog"><p className='links'>Nuestros Asesores</p></Nav.Link>
            
          </Nav>
          </Container>
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                <p className='links'>Bienvenido </p>
            </Navbar.Text>
        </Navbar.Collapse>
  
  </Navbar>

  )
}

export default NavegadorLog