import React from 'react'
import { Container } from 'react-bootstrap'
import CalCard from '../../Cards/CalCard'
import BookingForm from './BookingForm'

const BookingTool = () => {
  return (
    
    <Container fluid className='Content'>
    <CalCard>
    <BookingForm/>
    </CalCard>
  </Container>
  )
}

export default BookingTool