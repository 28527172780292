import {React} from 'react'
import {Form, Button } from "react-bootstrap"
import '../../Login/styles.css'
import Countdown from 'react-countdown'
import useAuth from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'

const WelcomeForm = () => {
   const {setMeetingInterval}= useAuth();
   const Navigate=useNavigate();
 

//renderer for countdown 
const renderer=({days,hours,minutes,seconds,completed})=>{
    if (completed){
        return (<> La promoción ha expirado!</>)
    } else{
        return (
         
        <p>vencimiento: <span> 
            <br/>
            {days} dias - {hours}:{minutes}:{seconds}</span>
        </p>
        )
    }
}

  return (
    <div className="masthead">

        <Form className="rounded p-4 p-sm-3" >
            <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label aria-required>Promoción del mes</Form.Label>
            <Form.Label aria-required style={{fontSize:'18pt'}}>15 min Gratis!</Form.Label>
            <Countdown date={'2024-06-01T00:00:00'} renderer={renderer}/>
                {/* <br/> */}
                <Button variant='warning' onClick={(e)=>{e.preventDefault(); setMeetingInterval("15");Navigate('/booking','replace:true')}}>15 min</Button>
                   

            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label aria-required>Una consulta rápida:</Form.Label>
                <br/>
                <Button variant='warning' onClick={(e)=>{e.preventDefault(); setMeetingInterval("30");Navigate('/booking','replace:true')}}>30 min</Button>

            </Form.Group>
            <Form.Group className="mb-5" controlId="formBasicEmail">
            <Form.Label aria-required>Necesito más Información:</Form.Label>
                <br/>
                <Button variant='warning' onClick={(e)=>{e.preventDefault(); setMeetingInterval("60");Navigate('/booking','replace:true')}}>45 min</Button>
            </Form.Group>

        </Form>
  
</div>
  )
}

export default WelcomeForm