//All reducers for AtreveteUSA app

//reducer for user registration 
export const UserReducer=(state,action)=>{
    switch(action.type){
    case "ADD_USER":
        // notice the usage of input and value for this user forms 
        return {...state, [action.payload.input]:action.payload.value}

        default:
            return state; 
}};

//reducer for appointments 
export const ApptReducer=(state,action)=>{
    switch (action.type){
        case "ADD_APPOINTMENT":
        //need to use input and value since the info is comming from a form
        return {...state,[action.payload.input]:action.payload.value}
        default:
                return state
    }
}
