import {React,useState} from 'react'
import { Button,  Form } from 'react-bootstrap'
import '../../Login/styles.css'
import useAuth from '../../Hooks/useAuth'
import Calendar from '../Calendar'
import ConfirmationRequest from '../../Modals/BookingModal'


const BookingForm = () => {
    const {apptDispatch,booking,meetingInterval}= useAuth(); //use only dispatch since we are only collecting the data
    const [showCalendar,setShowCalendar]=useState(false)

    const HandleSubmit=(e)=>{
        e.preventDefault();
        // adding time duration to the appointment 
        const newSelect=meetingInterval.toString();
        apptDispatch({
            type:"ADD_APPOINTMENT",
            payload:{
                input:'duration',
                value:newSelect 
            }
        })
        setShowCalendar(true)
    }

    const HandleOnChange=(e)=>{
        e.preventDefault();
        apptDispatch({
            type:"ADD_APPOINTMENT",
            payload:{
                input:e.target.name,
                value:e.target.value 
            }
        })
    }

  return (
    <div className='masthead mb-4'>
        <Form className="rounded p-4 p-sm-3 mb-3" onSubmit={(e)=>{HandleSubmit(e)}}>
            <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label aria-required>Tú Nombre:</Form.Label>
                <br/>
                    <Form.Control 
                    required
                    type="input" 
                    placeholder="John Doe" 
                    name='name' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label aria-required>Correo Electrónico:</Form.Label>
                <br/>
                    <Form.Control 
                    required
                    type="email" 
                    placeholder="correo@email.com" 
                    name='email' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>

            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label aria-required>Número de WhatssApp</Form.Label>
                <br/>
                    <Form.Control 
                    required
                    type="input" 
                    placeholder="+52-(448)-XXX-XXXX" 
                    name='whatss' 
                    autoComplete="off"
                    onChange={(e)=> {HandleOnChange(e)}}/>
                    <Form.Control.Feedback type="invalid">
            Please provide a valid number.
          </Form.Control.Feedback>
            </Form.Group>
            <Button variant='secondary' type='submit'>Calendario</Button>
        </Form>
         {showCalendar ? (<Calendar />):(<></>)}
         {booking ?(<ConfirmationRequest/>):(<></>)}
         

    </div>
  )
}

export default BookingForm