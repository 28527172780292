import React from 'react'
import { Button, Container,Card } from 'react-bootstrap'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'

const Tienda = () => {
  return (
    <div>
        <Navegador />
        <Container className='d-flex justify-content-center align-items-center Content'>
          <Card style={{width:'25rem', backgroundColor:'#072C2650',color:'#FFFFFF'}} >
            <Card.Header style={{fontSize:'16pt', fontWeight:'bold'}}>Nuestro Libro</Card.Header>
            
            <Card.Img src='https://m.media-amazon.com/images/I/51kafRcHmBL._SX482_BO1,204,203,200_.jpg' alt='fotografiadeLibro'
             style={{height:'12rem',width:'auto'}}/>
            <Card.Title>Guía En Español</Card.Title>
            <Card.Body>Una guía en el proceso de buscar, encontrar y aceptar una propuesta laboral, implicaciones al cambiar de país en 
              términos económicos, legales, culturales y demás; basada en información y experiencias de profesionistas trabajando en Estados Unidos.</Card.Body>
            <Card.Footer>
              <Button variant='warning' style={{backgroundColor:'#FAF2BF'}} href='https://a.co/d/4IVrFln' target='_blank'>comprar</Button>
            </Card.Footer>

          </Card>
        </Container>
        
        <Footer/>
    </div>
  )
}

export default Tienda