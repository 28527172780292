import React from 'react'
import{Container,Card,Button} from 'react-bootstrap'
import Map from '../../assets/MapUSA.png'

const OneOfUs = () => {
  return (
    <Container className='d-flex justify-content-center align-items-center mt-3'>
          <Card style={{width:'25rem', backgroundColor:'#072C2650',color:'#FFFFFF'}} >
            <Card.Header style={{fontSize:'16pt', fontWeight:'bold'}}>Nosotros</Card.Header>
            
            <Card.Img src={Map} alt='mapadeUSA'
             style={{height:'auto',width:'22rem'}}/>
            <Card.Title>Nuestro Equipo</Card.Title>
            <Card.Body>En AtreveteUsa y MextoMex conformamos una comunidad de profesionistas de todas jerarquías, creencias y enfoques; que queremos 
                compartir nuestras experiencas de vida de mas de 20 años en los Estados Unidos  </Card.Body>
            <Card.Footer>
              <Button variant='warning' style={{backgroundColor:'#FAF2BF'}} href='/registro' target='_blank'>Unete!</Button>
            </Card.Footer>

          </Card>
        </Container>
  )
}

export default OneOfUs