import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./styleModal.css"

import {useNavigate} from 'react-router-dom'

const MyModal=(props)=> {
  const Navigate = useNavigate();
  const AppInfo=JSON.parse(localStorage.getItem('STOAppointment'))
 console.log(AppInfo);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Atención  {AppInfo.name} !
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Se canceló el proceso de tú cita</h4>
        <p>
          Notas Importantes:
          <br/>
          No se ha cargado tu cuenta 
          <br/>
          Dejanos un mensaje:  
           <span> <a target="_blank"  rel="noopener noreferrer" href='https://wa.me/12489894843'>
                    <i style={{"fontSize":"3rem" ,"marginLeft":"14px"}} className="fa-brands fa-whatsapp fa-6x whatts"></i></a> </span>
        </p>
        
                   
               
      </Modal.Body>
      <Modal.Footer>
       
        <Button id='confirm' variant='success' onClick={()=> Navigate('/home')}> 
        <span><i className="fa-duotone fa-badge-check me-2"/>Continuar</span>
         </Button>
      </Modal.Footer>
    </Modal>
  );
}

const CancelPayment=()=> {
  const [modalState,setModalState]=useState(true)


  return (
    <>
  

      <MyModal
        show={modalState}
        onHide={() => setModalState(false)}
      />
    </>
  );
}

export default CancelPayment