import React from 'react'
import Footer from '../../Components/Footer'
import Navegador from '../../Components/NavBar'
import BookingTool from '../../Components/Booking/SchedulingForm'

const BookingSchedule = () => {
  return (
    <div className='Content'>
    <Navegador />
    <BookingTool />
    <Footer/>
    </div>
  )
}

export default BookingSchedule