import React from 'react'
import Navegador from '../../Components/NavBar'
import './Style.css'
import Carousel from 'react-bootstrap/Carousel';
import TeamFlag from '../../assets/pic1.jpg'
import BWoman from '../../assets/pic3.jpg'
import Calculator from '../../assets/pic2.jpg'
import {Col,Row,Card, Container,Button} from 'react-bootstrap';
import ReviewCard from '../../Components/ReviewCard';
import Footer from '../../Components/Footer';


const Home= () => {
  return (
    <div>
      <Navegador/>
   <Container fluid className='Content'>
        <h1>Eres profesionista y quieres trabajar en los Estados Unidos?</h1>
        <div className='box mt-1'>
          <Button variant='warning' href='/agenda' className='ActionCall my-2'>Pregúntanos Como !</Button>
          
      
        
      <Row className='mb-2'>
      <Col md={3}>
        <Container style={{colorBackground:'orange'}} className='position-relative'>
          <p style={{fontSize:'22pt', color:'#FFFFFF', marginTop:'4rem', fontWeight:'bolder'}} className='p-1'> 
          Somos tus guías en el proceso de buscar, encontrar y aceptar una propuesta laboral en Estados Unidos. 
          </p>
        </Container>
      </Col>
      <Col md={6}>
                
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block "
                      src={TeamFlag}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Profesionista en Estados Unidos?</h3>
                  <p>
                    Nosotros te ayudamos en el proceso
                  </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block "
                      src={Calculator}
                      alt="Second slide"
                    />
                  <Carousel.Caption>
                    <h3>Necesitas Una Guía?</h3>
                    <p>Te podemos ayudar</p>
                  </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                   className="d-block"
                  src={BWoman}
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h3>Profesionista en Estados Unidos?</h3>
                  <p>
                    Nosotros te ayudamos en el proceso
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            </Col>
            <Col md={3}>
              <Row className='Reviews'>
                <ReviewCard/>
              </Row>
            </Col>
          </Row>
          {/* <Container fluid> */}
          <Button variant='warning' href='/agenda' className='ActionCall mt-2'>Pregúntanos Como !</Button>
        {/* </Container> */}
        </div>
        </Container>    
          <Footer/>
    </div>
  )
}

export default Home