import React from 'react'
import { Container,Row,Col,Button} from 'react-bootstrap'
// import logoBW from "../../components/images/MainPage/LogoBlanco.png"
import useAuth from '../Hooks/useAuth';

import Privacidad from '../Modals/Privacy';
import Terminos from '../Modals/TdS';

import './style.css'

const Footer = () => {
  const {privacy,setPrivacy, terms,setTerms}=useAuth();

  const HandlePrivacy=(e)=>{
   
    e.preventDefault();
    setPrivacy(!privacy)

  }

  const HandleTerms=(e)=>{
  
    e.preventDefault();
    setTerms(!terms)

  }


  return (
   
    <div className='LocFooter'>
        <Container fluid className='RibbonFooter'>
            <Row className="justify-content-center">
          {/* <img className="logobott" src={} alt="SolarMax"/> */}
           <h1 style={{textTransform:'uppercase'}}>Atrévete!</h1>
           <h3>A ser un profesionista en</h3>
           <h2>Estados Unidos</h2>
            </Row>
            <div className="BotRow mt-3 mb-3">
            <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://wa.me/12489894843'>
                    <i className="fa-brands fa-square-whatsapp fa-6x whatts"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.facebook.com/intrepidtravel'>
                        <i className="fa-brands fa-facebook fa-6x Cfacebook"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://www.instagram.com/atrevete_usa/'>
                    <i className="fa-brands fa-instagram fa-6x Ficon"></i></a>
                </span>
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://twitter.com/AtreveteUsa'>
                    <i className="fa-brands fa-x-twitter fa-6x Ctwitter"></i></a>
                </span>
              
                <span className='ribbon-as'>
                    <a target="_blank"  rel="noopener noreferrer" href='https://wa.me/12489894843'>
                    <i className="fa-brands fa-spotify fa-6x whatts"></i></a>
                </span>
            </div>
            
        </Container>

        <Container fluid className='privacy-tos'>
        
        
        <div className='footer-links'>
        
       
          <a href="#" onClick={HandlePrivacy}>PÓLITICA DE PRIVACIDAD</a> 
          {privacy && (<Privacidad/>)} 
          
          <a href="#" onClick={HandleTerms}>TERMINOS DE SERVICIO</a>
           {terms && (<Terminos/>) } 
         
          
         
         

        </div>
       
        <Row className='LastRibbon'>
          
          <div className='copyright'>
          Derechos Reservados Roccia Global 2024
        </div>
        </Row>
        
       
        </Container>    
    </div>
    
  )
}

export default Footer