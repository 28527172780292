import React from 'react';
import { Container, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useAuth from '../Hooks/useAuth';
import "./styleModal.css"

function MyVerticallyCenteredModal(props) {  


const HandleOnChange=()=>{
  console.log("here we need the reducer ");
}


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='ModStyle'
   
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Dejanos tu pregunta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <Container>
        <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="name" placeholder="John Doe" name="UserName" onChange={(e)=>{HandleOnChange(e)}} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Número de WhatsApp</Form.Label>
        <Form.Control type="phone" placeholder="+52 435 439 5355" name="WhatsappNum" onChange={(e)=>{HandleOnChange(e)}}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control type="email" placeholder="nombre@ejemplo.com" name="Email" onChange={(e)=>{HandleOnChange(e)}} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Describenos tu pregunta</Form.Label>
        <Form.Control as="textarea" rows={3} name="Content" onChange={(e)=>{HandleOnChange(e)}}/>
      </Form.Group>
    </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Enviar </Button>
      </Modal.Footer>
    </Modal>
  );
}

const SingleQ=()=> {
  const [modalShow, setModalShow] = React.useState(true);// change to false when its button activated 
  const {question,setQuestion}=useAuth();
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {setModalShow(false);
          setQuestion(!question)     
    }
        }
      />
    </>
  );
}

export default SingleQ