import React, {useEffect, useRef, useState} from "react";
import useAuth from "../Hooks/useAuth"
import { AuthService } from "../Authentication/AuthService";
import {Form, Button, Container,Row, InputGroup} from "react-bootstrap"
import "../Login/styles.css"
import { useNavigate, useLocation} from "react-router-dom";
// import useAxiosPrivate from "../Hooks/useAxiosPrivate";


function LoginFrm() {

// declaring hooks and refs
// const axiosPrivate=useAxiosPrivate();
const {auth, setAuth, persist, setPersist} = useAuth();
const [muestra,setMuestra]=useState(false)
const EmailRef= useRef();
const [emailInput, setEmailInput] = useState("");
const PasswordRef = useRef();
const [passwordInput, setPasswordInput] = useState("");
const [message, setMessage] = useState(null);
const navigate = useNavigate();
const location = useLocation();
const from = location.state?.from?.pathname || "/";

useEffect(()=>{
},[])

const HandleHide=(e)=>{
    e.preventDefault();
    setMuestra(!muestra)
}
const HandleSubmit = async (e) => {
        e.preventDefault();
        const user = {
            username: EmailRef.current.value,
            password: PasswordRef.current.value
             };
           
        // section to add the authentication comparisons 
        if (!EmailRef.current.value || !PasswordRef.current.value) {
            return setMessage("Faltan campos de e-mail y password! ");   
        }
  try{
    await AuthService.login(user) 
    // await axiosPrivate.post('/user/login',user,{withCredentials:true})
   .then(res=>{
        const { isAuthenticated, user, roles, accessToken} = res.data; //change this to "res" only if Authservice is used 
        setAuth({ roles,user, isAuthenticated,accessToken })
        setPasswordInput("");
        setEmailInput("");
        navigate(from, {replace:true})
             })
   }catch (err){
    if (err.response.status === 401) {    
        setMessage("Alto!: e-mail o password no autorizados");
      }
    if(!err?.response){
        setMessage("No Server Respose")
    }
    } 
    }

    const togglePersist=()=>{
        setPersist(prev=> !prev) // persist is set to the opposite of the previous state
    }
    // adding a use effect to monitor when the user checks the box and changes the state of persist 
    useEffect (()=>{
// if persist state changes then we store its value into the localstorage
localStorage.setItem("persist",persist);
    },[persist])
    
    return(
        <div className="masthead">
               <div className="color-overlay d-flex justify-content-center align-items-center">
                <Form className="rounded p-4 p-sm-3" onSubmit={HandleSubmit} >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Correo Electrónico:</Form.Label>
                            <Form.Control type="email" 
                            placeholder="Ingresa tu correo" 
                            ref={EmailRef} 
                            value={emailInput} 
                            autoComplete="off"
                            onChange={(e)=> setEmailInput(e.target.value)}/>
                            <Form.Text className="dissclose">
                               Los datos serán encriptados para tu seguridad.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Contraseña:</Form.Label>
                            <InputGroup>
                                <Form.Control type={muestra? ("text"):("password")}
                                placeholder="Ingresa tu contraseña" 
                                ref={PasswordRef} 
                                value={passwordInput} 
                                autoComplete="off"
                                onChange={(e)=> setPasswordInput(e.target.value)} />
                                    <InputGroup.Text style={{backgroundColor:'#FFFFFFDD', color:'purple'}}>
                                    <i onClick={HandleHide} className={muestra? ("fa-duotone fa-eye-slash"):("fa-duotone fa-eye")}></i>
                                    </InputGroup.Text>
                                </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check 
                            type="checkbox" 
                            label="Mantenerme conectado"
                            id="persist"
                            onChange={togglePersist}
                            checked={persist}
                            />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="secondary" size="lg" type="submit" >
                                 Entrar
                            </Button> 
                        </div>
             </Form>
            </div>
            <div className="errorMessage" >{message}</div>
            <Row >
                <Container className="py-4">
                    <section>No tienes tu cuenta? </section>
                    <span><Button variant="outline-secondary" onClick={()=>navigate("/registro",{replace:true})} >Registrate!</Button></span>
                </Container>
            </Row>
        </div>
    )
}

export default LoginFrm