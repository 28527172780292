// Use this file for all API calls including axios calls to the backend 
// calls no relacionada a la autenticación
import axios from "axios";

const APICall = {
    Register:(Payload)=>{
        return axios.post('/user/new',Payload)
    },

    login:(Payload)=>{
        return axios.post('/user/login',Payload)
    },

    Checkout:(Payload)=>{
        return axios.post('/checkout',Payload)
    }

}

export default APICall