import React,{useState, useEffect} from 'react'
import { Offcanvas,Container, Button } from 'react-bootstrap'
import useAuth from '../Hooks/useAuth'

const Guide = (props) => {
const {showGuide,setShowGuide}=useAuth()
const [guideBody,setGuideBody]=useState({})
const[topicList,setTopicList]=useState([])
const handleClose = () => {setShowGuide(!showGuide);}
const Tema=props.Topic

useEffect (()=>{
const Meat=(Tema)=>{
  let Content={};
    switch (Tema) {
        case "BeforeArrival":     
               Content.Title="Antes de Llegar:";
                Content.Description= "Recomendaciones de como encontrar trabajo, como encontrar reclutadores en las diferentes áreas de Estados Unidos y que tu sepas que podemos ser tus guías en esta etapa.";
                Content.list=['Proceso de entrevistas',
                    'Requisitos para el trabajo',
                   'Negociación de sueldo',
                    'Paquetes de prestaciones',
                    'Tipos de visas',
                    'Experiencias en proceso de visa']
          break;
            case "OnArrival":
            
              Content.Title="Cuando Llegas:";
              Content.Description="Recomendaciones en como hacer mas llevadera la transferencia de un país a los Estados Unidos.";
              Content.list=['Estados Unidos más allá del mapa',
                    'Adquirir un vehículo',
                   'El lado psicológico de adaptación',
                    'Ser parte del sistema',
                    'Economía familiar',
                    'Trámites más comunes'];
            
           break; 
            case "AfterArrival":
              Content.Title="Despues de que llegaste:";
              Content.Description= "Recomendaciones de como ajustarte a tu nuevo estilo de vida y que tú sepas que podemos ser tus guías en esta etapa.";
              Content.list=['El clima',
                    'Dólares o pesos',
                    'Presupuesto',
                    'Como vives',
                    'Regla 2-5-7-10',
                    'Viendo al futuro',
                    'Testimoniales'];
          break;
        default:
            break;
           
    }
   
   setGuideBody(Content)
   setTopicList(Content.list)

    return Content
 }

 Meat(Tema);
},[])

  return (
    <Offcanvas show={showGuide} onHide={handleClose}  placement='start' >
        <Offcanvas.Header style={{backgroundColor:'#072C26DD'}} closeButton>
        <Offcanvas.Title style={{color:'#FFFFFF'}}>{guideBody.Title}      
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{backgroundColor:'#000000', height:'50vh'}} >
        <p style={{color:'#ffffffAA',textShadow:'none'}}>{guideBody.Description}</p>
        <Container>
          { topicList? (
            topicList.map((item)=>(
              <li key={item.id} style={{color:'#FFFFFF'}}>{item}</li>
            ))
          ):(<></>) }
          <Button variant='warning' href='/agenda' className='ActionCall mt-5'> Pidenos Ayuda!</Button>
        </Container>
        </Offcanvas.Body>
      </Offcanvas>
  )
}

export default Guide