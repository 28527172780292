import React, {useEffect, useRef, useState} from "react";
import useAuth from "../Hooks/useAuth";
import { AuthService } from "../Authentication/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Button, Form, Container  } from "react-bootstrap";
import logo from '../../assets/logo_provisional.png';
import './styles.css';
import APICall from "../APICalls";

const Registro = () => {
    const {setAuth,userState,userDispatch}=useAuth();
    const [userLogin,setUserLogin]=useState({})
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
  

const HandleOnChange=(e)=>{
    e.preventDefault();
    userDispatch({
        type:"ADD_USER",
        payload:{
            input:e.target.name,
            value:e.target.value 
        }
    })

}    

const HandleSubmmit=async()=>{
    let usertoLog={}
    console.log(userState);
    try{
        await APICall.Register(userState)
        .then(res=>{
          
            usertoLog={
                username:res.data.email, 
                password:userState.password}
          
        
           
        })
    }catch (err){
        console.log(err);
    };

    try{
        console.log(usertoLog);
        await APICall.login(usertoLog)
        .then(res=>{
            console.log(res.data);
            const { isAuthenticated, user} = res;
          setAuth({ user, isAuthenticated })
          navigate(from, {replace:true})

        })
    }catch (err){console.log(err)}
};

  return (
    <div>
        <Container className="d-flex justify-content-center align-items-center mt-3">
          {/* <div className='center-login'> */}
    <Card style={{width:'22rem', backgroundColor:'#072C2620'}}>
        
      <Card.Body>
        <Card.Img src={logo} fluid style={{
            // width:'',
            height:'auto',
            
        }}/>
        <Card.Title>Regístrate ahora de manera gratuita!</Card.Title>
        <Container>
            <Form>

            <Form.Group className="mb-3" controlId="floatingTextarea">
                <Form.Label>Elige tu nombre de usuario</Form.Label>
                <Form.Control type="text" placeholder="Nombre de usuario" name="username" onChange={(e)=>{HandleOnChange(e)}} />
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control type="email" placeholder="Correo electronico"  name="email" onChange={(e)=>{HandleOnChange(e)}} />
                <Form.Text className="text-muted">
                Nunca compartiremos tus datos personales con nadie.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="floatingTextarea">
                <Form.Label>Escribe tu nombre</Form.Label>
                <Form.Control type="text" placeholder="Primer nombre"  name="FirstName" onChange={(e)=>{HandleOnChange(e)}} />
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="floatingTextarea">
                <Form.Label>Escribe tu apellido</Form.Label>
                <Form.Control type="text" placeholder="Apellido" name="LastName" onChange={(e)=>{HandleOnChange(e)}} />
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control type="password" placeholder="Password" name="password" onChange={(e)=>{HandleOnChange(e)}}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Repite tu contraseña</Form.Label>
                <Form.Control type="password" placeholder="Password"  name="password2" onChange={(e)=>{HandleOnChange(e)}}/>
            </Form.Group>
            <Button style={{backgroundColor:'#94dd26',border:'none',color:'#000000'}} type="submit" onClick={(e)=>{e.preventDefault();HandleSubmmit();}}>
                Registrar
            </Button>
            <br></br>
            <Form.Text className="legend2">
                ¿Ya tienes una cuenta? Has clic en el enlace de abajo para iniciar sesión
            </Form.Text>
            <br></br>
            <a href='/login' role='button' style={{color:'#94dd26'}}>Inicio de sesión</a>
            <br></br>
           
            </Form>
        </Container>
      </Card.Body>
    </Card>

    {/* </div> */}
    </Container>
    </div>
  )
}

export default Registro